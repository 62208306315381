.section {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(24, 1fr);
  height: 900px;

  @media (width < 1024px) {
    height: 700px;
  }
}

.border_card {
  border-radius: 2rem;
}

.border_card_center {
  border: 2px solid white;
  grid-row: 1 / 16;
  border-top-left-radius: 0;
  border-top: none;
  border-top-right-radius: 0;
  grid-column: 5 / 21;
  grid-row: 1 / 21;

  @media (width < 1024px) {
    display: none;
  }
}

.border_card_left {
  border: 2px solid rgb(226 236 249);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  grid-column: 1 / 13;
  grid-row: 6 / 18;

  @media (width < 1024px) {
    display: none;
  }
}

.border_card_right {
  border: 2px solid rgb(226 236 249);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  grid-column: 14 / 25;
  grid-row: 4 / 23;

  @media (width < 1024px) {
    display: none;
  }
}

.slider {
  grid-column: 6 / 12;
  grid-row: 8 / 20;

  @media (width < 1024px) {
    grid-column: 3 / 23;
    grid-row: 12 / 24;
  }
}

.editorial {
  @media (width < 1024px) {
    text-align: center;
  }
}

.photo_position {
  grid-column: 15 / 20;
  grid-row: 7 / 20;

  @media (width < 1024px) {
    grid-column: 3 / 23;
    grid-row: 4 / 11;
    display: flex;
    justify-content: center;
  }
}

.photo {
  width: 100%;
  height: 100%;
  max-width: 450px;
  border-radius: 1rem;
  object-fit: cover;
  object-position: center;

  @media (width < 1024px) {
    border-radius: 100%;
    width: 200px;
    height: 200px;
  }
}

.header {
  max-width: 450px;
  margin: 0;

  @media (width < 1024px) {
    max-width: none;
    font-size: 28px;
  }
}

.author {
  margin-top: 2rem;
  margin-bottom: 0;
  line-height: 28px;
}

.link {
  margin-top: 1rem;

  @media (width < 1024px) {
    display: inline-block;
  }
}

.dots {
  text-align: left;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  @media (width < 1024px) {
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
  }

  // only way to target react-slick's <li> elements, they don't expose a class name prop for these elements
  li {
    vertical-align: middle;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.dot {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50rem;
  background: white;
  border: 1px solid rgb(196 196 196);

  &[data-active="true"] {
    background-color: #2d67c0;
    border: 1px solid #2d67c0;
  }

  &:hover {
    background-color: #2d67c0;
    border: 1px solid #2d67c0;
  }
}

.arrow {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
