@use "../mixins";
@use "../constants";
@use "../common/fonts/mixins" as fonts;

$timeline-centerLine-afterHeight: 100px;
$timeline-centerLine-topOffset: 20px;
$timeline-tile_padding: 16px;
$timeline-tile_marginBottom: 30px;

.timeline-section {
  padding: 100px 0;

  p {
    max-width: 900px;
  }

  .timeline-container {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    position: relative;
    width: calc(100% * 8 / 12);
    margin: auto;
    margin-top: 100px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      width: 100%;
    }
  }

  .timeline-container::before {
    // vertical center line
    content: "";
    position: absolute;
    left: 50%;
    top: $timeline-centerLine-topOffset;
    transform: translateX(-50%);
    width: 1px;
    height: calc(100% - #{$timeline-centerLine-afterHeight + $timeline-centerLine-topOffset});
    background-color: black;
    display: block;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      left: 0;
    }
  }

  .timeline-container::after {
    // trailing tail line
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: $timeline-centerLine-afterHeight;
    bottom: 0;
    // background: repeating-linear-gradient(
    //   0deg,
    //   black 0,
    //   black 8px,
    //   transparent 8px,
    //   transparent $timeline-tile_padding
    // );
    background: linear-gradient(to bottom, black, transparent);
    display: block;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      left: 0;
    }
  }

  .timeline-period {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 50px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      align-items: flex-start;
    }
  }

  .timeline-period_margin1995 {
    margin-bottom: 200px;
  }

  .timeline-period_margin2004 {
    margin-top: -150px;
  }

  .timeline-period_margin2009 {
    margin-top: -100px;
  }

  .timeline-period_margin2010 {
    margin-top: -175px;
  }

  .timeline-period_margin2013 {
    margin-top: -125px;
  }

  .timeline-period_margin2015 {
    margin-top: -124px;
  }

  .timeline-period_margin2020 {
    margin-top: -70px;
  }

  .timeline-period_margin2021 {
    margin-top: -50px;
  }

  .timeline-period_marginToday {
    margin-top: -50px;
  }

  .timeline-tile_marginSeriesA {
    margin-top: -145px;
  }

  .timeline-period-mobileMargin {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-bottom: 20px;
      margin-top: 0;
    }
  }

  .timeline-period_2017-zIndex {
    z-index: 1;
  }

  .timeline-yearContainer {
    position: relative;
    margin-bottom: 20px;
  }

  .timeline-yearContainer:before {
    content: "";
    transform: translateX(-50%);
    position: absolute;
    top: 50%;
    width: 40px;
    height: 1px;
    display: block;
    background: black;
  }

  .timeline-yearContainer-left:before {
    left: calc(50% - 20px);
    @include mixins.respond-max(constants.$breakpoint-sm) {
      left: 0;
      transform: none;
    }
  }

  .timeline-yearContainer-right:before {
    left: calc(50% + 20px);
    @include mixins.respond-max(constants.$breakpoint-sm) {
      left: 0;
      transform: none;
    }
  }

  .timeline-yearContainer:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    margin: auto;
    border-radius: 50%;
    background-color: black;
    opacity: 1;
    transition-property: opacity, transform;
    transition: 0.25s cubic-bezier(0.32, 0.01, 0, 1);
    width: $timeline-tile_padding;
    height: $timeline-tile_padding;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      left: -8px;
      transform: translateX(0%) translateY(-50%);
    }
  }

  .timeline-year {
    margin: 0;
    font-size: 40px;
    position: relative;
    min-width: 102px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      font-size: 32px;
    }
  }

  .timeline-year-left {
    transform: translateX(-97%) translateY(0);
    margin-right: 10px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      transform: translateX(60px);
      margin-right: 0;
    }
  }

  .timeline-year-right {
    transform: translateX(97%) translateY(0);
    margin-left: 10px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      transform: translateX(60px);
      margin-left: 0;
    }
  }

  .timeline-tiles {
    width: calc(100% * 8 / 12);
    padding-left: 8px;
    padding-right: 8px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      width: 90%;
    }
  }

  .timeline-tile {
    background-color: #f9f9f9;
    transition: transform 0.25s cubic-bezier(0.32, 0.01, 0, 1);
    position: relative;
    padding: $timeline-tile_padding;
    width: calc(100% - #{$timeline-tile_padding});
    margin-bottom: 30px;
    border-radius: 5px;
  }

  .timeline-tile-left {
    transform: translateX(-50%) translateY(0);
    margin-left: -32px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      transform: none;
      margin-left: 0;
    }
  }

  .timeline-tile-right {
    transform: translateX(50%) translateY(0);
    margin-left: $timeline-tile_padding;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      transform: none;
      margin-left: 0;
    }
  }

  .timeline-tile_blue {
    background-color: #00b6f8;

    .timeline-paragraph {
      color: white;
    }
  }

  .timeline-tile_margin2017 {
    margin-top: -336px;
  }

  .timeline-tile_margin2000 {
    margin-top: -50px;
  }

  .timeline-tile-mobileMargin {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-top: 0;
    }
  }

  .timeline-paragraph {
    @include fonts.acumin-pro();
    font-size: 14px;
    line-height: 28px;
    color: #555555;
    margin: 0;

    ol,
    ul {
      padding-left: $timeline-tile_padding;
    }
  }

  .icon-rocket {
    margin-bottom: 10px;
  }
}
