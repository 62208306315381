@use "../../../styles/constants";
@use "../../../styles/mixins";

.details_wrapper {
  border-bottom: 1px solid #3e495e;
  color: white;
}

.details_btn {
  display: flex;
  outline: none;
  border: none;
  background: transparent;
  color: white;
  width: 100%;
  cursor: pointer;
  transition: background-color ease 300ms;
  padding: 0;
}

$icon-size: 2rem;
$icon-size-sm: 1.5rem;
$icon-spacing: 1rem;

.img {
  margin-right: $icon-spacing;
  width: $icon-size;
  height: $icon-size;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    width: $icon-size-sm;
    height: $icon-size-sm;
  }
}

.summary,
.paragraph {
  padding: 20px;
  margin: 0;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding: 12px;
  }
}

.summary {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  &.open {
    font-weight: bold;
  }

  @include mixins.respond-max(constants.$breakpoint-sm) {
    font-size: 16px;
  }
}

.paragraph {
  margin-left: $icon-size + $icon-spacing;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    margin-left: $icon-size-sm + $icon-spacing;
  }
}
