@use "../constants";
@use "../mixins";

.sec-hero-parallax-spacing {
  background-color: constants.$security-blue;
  width: 100%;
  position: relative;
  z-index: -2;
  height: 200px;
  margin-top: -100px;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    display: none;
  }
}

.sec-parallax-section.sec-hero-parallax-desktop {
  @include mixins.respond-max(constants.$breakpoint-sm) {
    position: relative;
    z-index: 0;
  }
}

.hero-section.sec-hero-section {
  background-color: constants.$security-blue;
  width: 100%;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 150px;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding-top: 200px;
    padding-bottom: 0;
    margin-top: -150px;
  }

  .content {
    position: relative;
  }

  .icon-security-large {
    position: absolute;
    right: 5rem;
    bottom: -10rem;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: none;
    }
  }

  .heroSection-dashed-lines {
    left: -5%;
  }

  .hero-flex {
    display: flex;
    justify-content: space-between;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      flex-direction: column;
      align-items: center;
    }
  }

  h1 {
    max-width: 700px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      font-size: 40px;
      line-height: 54px;
    }
  }

  h5 {
    max-width: 500px;
    margin-bottom: 0;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      font-size: 20px;
      line-height: 34px;
      margin-bottom: revert;
    }
  }

  .ribbon-container {
    position: relative;
    width: calc(400px + 2rem);
    left: 2rem;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      width: 100%;
      left: 0;
    }

    &.ribbon-desktop {
      @include mixins.respond-max(constants.$breakpoint-sm) {
        display: none;
      }
    }
  }

  .award-ribbon {
    bottom: -25%;
    right: 0rem;
    width: calc(100% - 4rem);
    position: absolute;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      position: relative;
      left: 0;
      bottom: 0;
    }
  }

  .award-ribbon::before {
    content: "";
    background-color: inherit;
    height: 700px;
    top: -100%;
    width: 100%;
  }

  hr {
    width: 100%;
    background-color: white;
  }
}

.award-ribbon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(transparent 15%, #465984 100%);
  padding: 2rem 2rem 8rem 2rem;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% calc(100% - 60px), 0% 100%, 0% 0%);
  max-width: 432px;
}

.ribbon-parallax-mobile {
  padding-bottom: 75px;
  display: none;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    display: block;
  }
}
