@use "../constants";
@use "../mixins";

.sec-trust-expertise-section {
  padding: 125px 0 75px;
  color: white;
  background-color: constants.$security-blue;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding: 75px 0 0;
  }

  .dashed-line-container {
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .sec-trust-expertise-dashed-lines {
    width: 275%;
    left: -150%;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      width: 80%;
      left: -1rem;
    }
  }
}
