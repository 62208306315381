/*---------------------------------*/
/* Fonts */
/*---------------------------------*/
$font-main: Helvetica, Arial, sans-serif;

/*---------------------------------*/
/* Colors */
/*---------------------------------*/
$border-gray: #f0f0f0;
$btn-black_hover: #292929;
$btn-white_hover: #ececec;
$btn-blue: #0070c0;
$btn-blue_hover: #0082de;
$nav-btn_gray: #777777;
$security-green: #5ab059;
$security-green-dark: #4e9f4d;
$security-blue: #254180;
$security-blue-dark: #050912;

/*---------------------------------*/
/* Borders */
/*---------------------------------*/
$border-radius: 3px;

/*---------------------------------*/
/* Breakpoints */
/*---------------------------------*/
$breakpoint: 1024px;
$breakpoint-xxsm: 320px;
$breakpoint-xsm: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-sml: 1440px;
$breakpoint-xl: 1600px;
$breakpoint-xxl: 1920px;

/*---------------------------------*/
/* SPACING - widths/margins/padding */
/*---------------------------------*/
$section-width: 90%;

$section-maxWidth-xxsm: 750px;
$section-maxWidth-xsm: 900px;
$section-maxWidth-small: 1200px;
$section-maxWidth: 1400px;
$section-maxWidth-large: 1600px;

$section-mobilePadding-sm: 30px;
$section-mobilePadding-md: 50px;
$section-mobilePadding-mdX2: 100px;
$section-mobilePadding-lg: 75px;
$section-mobilePadding-xl: 150px;

$margin_xxsm: 5px;
$margin-xsm: 12px;
$margin-sm: 30px;
$margin-md: 50px;
$margin-lg: 75px;
$margin-xl: 150px;

@mixin heroSection-spacing {
  padding: 200px 0 200px;
  margin-top: -150px;
}
