.section {
  height: 8rem;
  display: flex;
  gap: 2rem;
  background-color: #f8f8f8;
}

.box {
  flex: 1;

  &[data-offset="true"] {
    flex: 0.75;
  }
}

.box_left {
  border-right: 1px solid rgb(246, 195, 199);
}

.box_right {
  border-left: 1px solid rgb(204, 219, 240);
}
