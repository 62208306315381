// NOTE - must use an abolute path when importing other scss files inside css modules
@use "styles/constants.scss";
@use "styles/mixins.scss";

.editorial_quote_slide {
  display: flex;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    flex-direction: column;
    align-items: center;
  }
}

.quote_photo {
  width: 320px;
  height: 500px;
  background-color: black;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-top: 2rem;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    width: 175px;
    height: 175px;
    object-fit: cover;
    border-radius: 50%;
    object-position: -10px 10%;
  }
}

.quote_col {
  position: relative;
  padding: 1rem 3rem 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding: 3rem 0 1rem;
  }
}

.main_quote {
  line-height: 48px;
  font-weight: 600;
  margin: 0;
  position: relative;
  margin-bottom: 30px;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    font-size: 23px;
    line-height: 32px;
    max-width: none;
  }
}

.byline_desktop {
  margin-bottom: 2rem;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    display: none;
  }
}

.byline_mobile {
  @include mixins.respond-min(constants.$breakpoint-sm) {
    display: none;
  }
}

.dashed_lines_container {
  position: relative;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    display: none;
  }
}

.dashed_lines_width {
  width: 100%;
}

.question {
  font-size: 29px;
  margin: 2rem 0 0 0;
  font-weight: 600;
  line-height: 38px;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    display: none;
  }
}

.answer {
  font-size: 29px;
  line-height: 38px;
  font-weight: 300;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    display: none;
  }
}

.read_more {
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: max-content;
}
