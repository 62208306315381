@use "../mixins";
@use "../constants";

.home-equity-hero-section {
  background-image: url("../../images/photos/photo-hero-min.jpg");
  width: 100%;
  height: 36vw;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: -100px 50px;
  @include mixins.respond-max(950px) {
    background-position: -200px 50px;
  }
  @include mixins.respond-max(600px) {
    background-image: url("../../images/photos/photo-hero-mobile.jpg");
    background-position: 0px 50px;
    margin-bottom: 350px;
  }

  .mobile-text-container {
    height: 200px;
    margin-top: 120%;
    @include mixins.respond-min(600px) {
      display: none;
    }
    @include mixins.respond-min(380px) {
      margin-top: 110%;
    }
    @include mixins.respond-min(500px) {
      margin-top: 100%;
    }
  }

  .hero-content {
    margin-top: 150px;
    @include mixins.respond-max(1000px) {
      margin-top: 0px;
    }
    @include mixins.respond-max(600px) {
      display: none;
    }
  }
  .hero-title {
    font-size: 24px;
    @include mixins.respond-max(600px) {
      font-size: 20px;
    }
  }
  .hero-bold-title {
    font-weight: 800;
  }
  .hero-subtitle {
    font-size: 46px;
    width: 50%;
    line-height: 60px;
    margin-top: 20px;
    @include mixins.respond-max(1000px) {
      font-size: 36px;
      line-height: 40px;
    }
    @include mixins.respond-max(600px) {
      font-size: 32px;
      width: 100%;
    }
  }
  .equity-navbar {
    display: flex;
    justify-content: space-between;
  }

  .flueid-logo {
    background-image: url("../../images/logos/logo-flueid.png");
    height: 40px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 5%;
  }
}
