@use "../mixins";
@use "../constants";

.loss-mit-container {
  .graph-card-container {
    position: relative;

    .graph-text-loss-mit {
      width: 400px;
      font-size: 18px;
      line-height: 24px;
      position: absolute !important;
      border: 1px solid #f0f0f0;
      padding: 40px;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.849);
      backdrop-filter: blur(5px);
      box-shadow: 0px 0px 20px rgba(214, 214, 214, 0.849);

      @include mixins.respond-max(900px) {
        display: none;
      }
    }

    .para1 {
      top: 30px;
      left: 40%;
      right: 0;
      margin: auto;

      @include mixins.respond-min(2000px) {
        left: 30%;
      }

      @include mixins.respond-max(1400px) {
        left: 55%;
        width: 320px;
      }
    }

    .para2 {
      top: 60px;
      left: 0;
      right: 45%;
      margin: auto;

      @include mixins.respond-min(2100px) {
        right: 35%;
      }

      @include mixins.respond-max(1300px) {
        right: 60%;
        width: 320px;
      }
    }

    .para3 {
      top: 120px;
      left: 55%;
      right: 0;
      margin: auto;

      @include mixins.respond-min(2200px) {
        left: 30%;
      }

      @include mixins.respond-max(1300px) {
        left: 55%;
        width: 320px;
      }
    }

    .para4 {
      top: 470px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .para5 {
      top: 470px;
      left: 50%;
      right: 0;
      margin: auto;

      @include mixins.respond-min(2100px) {
        left: 35%;
      }

      @include mixins.respond-max(1300px) {
        left: 55%;
        width: 320px;
      }
    }

    .graph-img-desktop {
      @include mixins.respond-max(900px) {
        display: none;
      }
    }

    .graph-img-mobile {
      @include mixins.respond-min(900px) {
        display: none;
      }
    }
  }

  .graph-section {
    background-color: #f4f4f4;
    padding-bottom: 80px;

    @include mixins.respond-max(900px) {
      padding-bottom: 40px;
    }

    .graph-para-text {
      font-size: 16px;
      text-indent: -18px;
    }

    .graph-header {
      font-size: 40px;
      text-align: center;
      width: 65%;
      margin: 0px auto;
      margin-bottom: 20px;
      padding-top: 90px;
      font-family: "acumin-pro";
      height: 50px;
      font-weight: 400;

      @include mixins.respond-max(900px) {
        font-size: 24px;
        width: 85%;
        margin-bottom: 0px;
        padding-top: 0px;
        line-height: 15px;
        height: 25px;
      }
    }

    .graph-header-sub {
      font-size: 24px;
      text-align: center;
      font-weight: 300;
      margin: 0 auto;
      margin-bottom: 40px;
      margin-top: 30px;
      width: 80%;
      line-height: 40px;

      @include mixins.respond-max(900px) {
        font-size: 14px;
        width: 85%;
        margin-bottom: 20px;
        line-height: 20px;
      }
    }

    .button-section-desktop {
      @include mixins.respond-max(900px) {
        display: none;
      }
    }

    .main-content-container {
      @include mixins.respond-max(900px) {
        padding-top: 100px;
      }

      .slick-slider {
        .slick-dots {
          display: flex !important;
          flex-direction: column;
          justify-content: flex-end;
          width: 3%;
          right: 20px;
          bottom: 220px;

          @include mixins.respond-max(1200px) {
            flex-direction: row;
            justify-content: center;
            right: unset;
            bottom: unset;
            width: 100%;
          }

          @include mixins.respond-max(600px) {
            display: none !important;
          }
        }
      }
    }

    #sticky-section {
      position: sticky;
      top: 0;
    }

    #scroll-sections {
      position: relative;
      z-index: 500;

      @include mixins.respond-min(600px) {
      }
    }

    .scroll-item {
      width: 70%;
      border: 1px solid #eeeeeedd;
      border-radius: 10px;
      margin: 0px 4% 110vw 4%;
      background-color: rgba($color: #ffffff, $alpha: 0.97);
      padding: 5% 10%;
      opacity: 0.5;
      box-shadow: 0 0 10px #c9dfe7;

      @include mixins.respond-max(600px) {
        margin: 100vw 4% 0px 4%;
        opacity: 0.3;
      }

      @include mixins.respond-min(900px) {
        opacity: 0;
        padding: 0px;
        margin: 0px;
        height: 1px;
        border: 0px;
      }

      &.active {
        opacity: 1;

        @include mixins.respond-min(900px) {
          opacity: 0;
        }
      }
    }

    .hiddencard {
      display: none;
    }

    #scrollcard-5 {
      opacity: 0 !important;
    }

    .graph-text-bottom {
      text-align: center;
      font-size: 20px;

      @include mixins.respond-max(1200px) {
        margin-top: 50px;
      }

      @include mixins.respond-max(700px) {
        width: 90%;
        margin: 0 auto;
        margin-top: 50px;
      }
    }

    .bottom-text-bold {
      font-weight: 700;
    }
  }

  .button-graph-section-mobile {
    z-index: 700;
    position: sticky;

    padding-bottom: 80px;
    background-color: #f4f4f4;

    @include mixins.respond-min(900px) {
      display: none;
    }

    .graph-text-bottom {
      text-align: center;
      font-size: 20px;

      @include mixins.respond-max(700px) {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}
