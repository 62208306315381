@use "../../../styles/mixins";

.section {
  padding-bottom: 8rem;
  background: linear-gradient(#f8f8f8, white);
}

.card {
  padding: 3rem;
  position: relative;
  border-radius: 1rem;
  box-shadow: 0px 0px 18px 5px #eaeaea;
}

.card:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  border: 2px solid transparent;
  background: linear-gradient(to right, rgb(246 195 199), rgb(204 219 240)) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.card_content {
  position: relative;
  z-index: 1;
}

.logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;

  @include mixins.respond-max(1024px) {
    flex-direction: column;
    gap: 3rem;
  }
}

.logo {
  height: 36px;
  object-fit: contain;
}
