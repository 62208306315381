// ============= main content grid ==========
.grid {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr) repeat(4, 2rem) repeat(6, 1fr);
  grid-auto-rows: 0.25fr;
}

// ============= numbers & descriptions elements ==========
.header {
  text-align: center;
}

.number_row {
  grid-row: var(--grid-row);
}

.number_col_left {
  grid-column: 1/8;
  text-align: right;

  @media (width < 1024px) {
    grid-column: 2 / 16;
    text-align: center;
  }
}

.number_col_right {
  grid-column: 10 / 16;

  @media (width < 1024px) {
    grid-column: 2 / 16;
    text-align: center;
  }
}

.number_header {
  font-size: 65px;
  font-weight: 300;
  color: #2e67c6;
  margin: 0 !important;

  sup {
    line-height: 0;
  }

  @media (width < 1024px) {
    font-size: 44px;
    // skooch the number header up above the its description paragraph
    grid-row: calc(var(--grid-row) - 1);
  }
}

.number_description {
  margin: 0 !important;
  display: flex;
  align-items: center;

  @media (width < 1024px) {
    justify-content: center;
    text-align: center;
  }
}

// ============= connected background border elements ==========
// https://stackoverflow.com/questions/51496204/border-gradient-with-border-radius - inspo
// this effect wasn't trivial 😂
// making background border gradients connected between gridded rows of number/text like:
// __________
//           |
//  number   | text
//           |______
//           _______
//           |
//  number   | text
//  _________|
//==================================

.border {
  position: relative;
  z-index: 0;
  --width: 2px;
  --pink: #f6c3c7;
  --blue: #ccdbf0;

  @media (width < 1024px) {
    display: none;
  }
}

.border_col_left {
  grid-column: 1 / 9;
}

.border_col_right {
  grid-column: 9 / 17;
}

.border_row_top {
  grid-row: 1 / 6;
}

.border_row_bottom {
  grid-row: 7 / var(--last-row);
}

// pseudo element that creates border radius and content mask for each background border element
.border_gradient::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: var(--border);
  border-radius: var(--radius, 50%);

  // fill the background with gradient
  background: var(--gradient);

  // this masks the inner content to make the element appear bordered by the above background gradient
  mask: linear-gradient(#000 0 0) exclude, linear-gradient(#000 0 0) content-box;
}

.border_bottom_right {
  @extend .border;
  @extend .border_col_left;
  @extend .border_row_bottom;
  @extend .border_gradient;

  // this is what aligns it with its corresponding top left border element
  width: calc(100% + var(--width));

  --border: 0px var(--width) var(--width) 0px;
  --radius: 0 0 2rem;
  --gradient: linear-gradient(40deg, var(--pink) 85%, var(--blue) 90%, #0000 95%);
}

.border_top_left {
  @extend .border;
  @extend .border_col_right;
  @extend .border_row_bottom;
  @extend .border_gradient;

  --border: var(--width) 0px 0px var(--width);
  --radius: 2rem 0 0 0;
  --gradient: linear-gradient(40deg, #0000 20%, var(--blue) 25%);
}

.border_top_right {
  @extend .border;
  @extend .border_col_left;
  @extend .border_row_top;
  @extend .border_gradient;

  width: calc(100% + var(--width));

  --border: var(--width) var(--width) 0 0;
  --radius: 0rem 2rem 0rem;
  --gradient: linear-gradient(171deg, var(--pink) 75%, var(--blue) 85%, #0000 90%);
}

.border_bottom_left {
  @extend .border;
  @extend .border_col_right;
  @extend .border_row_top;
  @extend .border_gradient;

  --border: 0 0 var(--width) var(--width);
  --radius: 0 0 0 2rem;
  --gradient: linear-gradient(170deg, #0000 20%, var(--blue) 25%);
}
