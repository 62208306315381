@use "../../../styles/common/fonts/mixins" as fonts;
@use "../../../styles/mixins";

.section {
  background: linear-gradient(#254180, #050912);
  color: white;
  padding: 6rem 0 10rem;
  position: relative;

  @include mixins.respond-max(1100px) {
    padding-bottom: 40px;
  }

  @include mixins.respond-max(1024px) {
    padding-top: 4rem;
  }
}

.content {
  margin-bottom: 8rem;
}

.tabs {
  display: flex;
  gap: 1rem;
  max-width: 900px;
}

.tabs_desktop {
  display: none;

  @include mixins.respond-min(1024px) {
    display: flex;
  }
}

.tabs_mobile {
  @include mixins.respond-min(1024px) {
    display: none;
  }
}

.tab {
  border: none;
  background: none;
  color: white;
  padding: 0.25rem;
  cursor: pointer;
  font-weight: 300;
  @include fonts.acumin-pro();
  font-size: 19px;

  &:hover {
    color: #ededed;
  }

  &[data-active="true"] {
    box-shadow: 0px 2px 0px 0px white;
    font-weight: 400;
  }
}

:global(.ui-popup-container).popup_wrapper {
  :global(.popup) {
    left: 0;
    top: 50px;
  }
}

.popup_button {
  border: 1px solid white;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background: none;
  color: white;

  :global(.icon-dropdownArrow) {
    transition: 0.3s ease transform;

    &:global(.rotateOpen) {
      transform: rotate(180deg);
    }
  }
}

.header {
  max-width: 800px;
  margin-top: 0;
}

.description {
  max-width: 900px;
}

.waves_wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;

  @include mixins.respond-max(1024px) {
    height: 60px;
  }
}

.waves {
  width: 100%;
  height: 100%;
  position: absolute;
}
