@use "../mixins";

$maxWidth: 800px;

.errorBoundary-fallbackUI {
  .content-border {
    border: 1px solid #f0f0f0;
    max-width: $maxWidth;
    margin: 40px auto;
    padding: 32px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include mixins.respond-max($maxWidth) {
      border: none;
    }
  }

  .errorBoundary-header {
    font-size: 26px;
    line-height: 31px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .errorBoundary-txt {
    font-size: 18px;
    line-height: 22px;
    max-width: 450px;
  }
}
