@use "../mixins";
@use "../constants";
@use "./heroSection.scss";
@use "./breakSection.scss";
@use "./graphSection.scss";
@use "./servicesSection.scss";

.loss-mit-container {
  .btn-container-equity-demo {
    width: 175px;
    margin: 0 auto;
  }
  .equity-hero-btn {
    width: 175px;
    text-align: center;
    padding: 10px 0px;
    border-radius: 5px;
    font-size: 14px;
    margin: 0 auto;
    margin-top: 20px;
    cursor: pointer;
  }
  .equity-btn-outline {
    border: 1px solid white;
  }
  .editorial-container {
    background-color: #f4f4f4;
    .editorial-cards_section {
      margin: 0 auto;
      padding-bottom: 30px;
      .section-content {
        width: 95%;
        h2 {
          margin-bottom: 0px;
          @include mixins.respond-max(1200px) {
            text-align: center;
          }
        }
        .txt-regular {
          margin-top: 15px;
          @include mixins.respond-max(1200px) {
            text-align: center;
          }
        }
      }
      .section-content.section-maxWidth-small {
        max-width: 1350px;
      }
    }
  }
  .demo-form-bottom-subtext-he {
    text-align: left;
  }
}
