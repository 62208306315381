.section {
  padding: 150px 0px;
}

:global(.slick-dots).dots_light {
  li {
    button:before {
      color: white;
    }
  }
}
