@use "../../../styles/common/fonts/mixins" as fonts;
@use "../../../styles/mixins";

.section {
  background-color: #f8f8f8;
}

.card {
  padding: 4rem;
  position: relative;
  border-radius: 1rem;
  box-shadow: 0px 0px 18px 5px #eaeaea;

  @include mixins.respond-max(1024px) {
    padding: 2rem;
  }
}

.card:after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  border: 2px solid transparent;
  background: linear-gradient(to right, rgb(246 195 199), rgb(204 219 240)) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.card_content {
  position: relative;
  z-index: 1;
}

.content_desktop {
  display: flex;
  gap: 4rem;
  margin: 0 auto;

  @include mixins.respond-max(1024px) {
    display: none;
  }
}

.content_mobile {
  display: none;

  @include mixins.respond-max(1024px) {
    display: block;
  }

  :global(.slick-dots) {
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
    padding: 0;
    position: relative;
    bottom: 0;
  }
}

.header {
  margin-top: 0;
}

.header_bold {
  font-weight: bold;
  margin-top: 0;
  color: #2e67c5;
}

.buttons {
  flex: 0.25;
}

.button {
  display: block;
  border: none;
  background: none;
  font-size: 18px;
  font-weight: 300;
  @include fonts.acumin-pro();
  cursor: pointer;
  padding: 0;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  &[data-active="true"] {
    font-weight: bold;
    border-bottom: 1px solid;
  }
}

.slides {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 1fr;
  flex: 1;
}

.slide {
  grid-column: 1/ 13;
  grid-row: 1 / 11;
}

.demo_link {
  grid-row: 11;
  grid-column: 1;
  display: block;
  width: max-content;
}

.demo_link_mobile {
  margin: 2rem auto 0;
  display: block;
  width: max-content;
}

.list {
  padding-inline-start: 1.25rem;
}
