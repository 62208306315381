.section {
  padding: 8rem 0 0;
  background-color: #f8f8f8;
}

.hr_gradient {
  height: 1px;
  background: linear-gradient(to right, rgb(246 195 199), rgb(204 219 240));
  border: none;
}

.clarify_header {
  margin-bottom: 0;
  color: #e44a58;
  font-weight: 500;
}

.cards_wrapper {
  position: relative;
  margin: 5rem auto 0;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.card {
  padding: 3rem;
  border: 1px solid #c7c7c7;
  border-radius: 1rem;
  box-shadow: 0px 0px 18px 5px #eaeaea;
  background-color: white;

  @media (width < 768px) {
    padding: 2rem;
  }
}

.card_headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.card_header_icon {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card_header {
  margin: 0;
  font-weight: bold;

  @media (width < 768px) {
    font-size: 28px;
  }
}

.card_subheader {
  margin: 0;
  color: #2e67c5;
  font-weight: 300;

  @media (width < 768px) {
    font-size: 28px;
  }
}

.link {
  color: #2e67c5;
}

.outlines {
  position: absolute;
  margin-top: -2rem;
  margin-left: -2rem;
  width: calc(100% + 4rem);
  height: calc(100% + 4rem);
  display: grid;
  grid-template-rows: repeat(16, 1fr);
  grid-template-columns: repeat(16, 1fr);

  @media (width < 1024px) {
    display: none;
  }
}

// creating a rounded border gradient is actually pretty gross and needs all this
.outline::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  border: 2px solid transparent;
  background: linear-gradient(to right, rgb(246 195 199), rgb(204 219 240)) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.outline {
  padding: 2rem;
  position: relative;

  &:nth-child(1) {
    grid-row: 1 / 7;
    grid-column: 3 / 15;
  }

  &:nth-child(2) {
    grid-row: 4 / 9;
    grid-column: 4 / 14;
  }

  &:nth-child(3) {
    grid-row: 8 / 12;
    grid-column: 1 / 12;
  }

  &:nth-child(4) {
    grid-row: 12 / 16;
    grid-column: 6 / 17;
  }
}
