@use "../constants" as constants;
@use "../mixins.scss" as mixins;
@use "../common/fonts/mixins" as fonts;
@use "./constants" as navConstants;

.navbar-container {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 200;
  transition: transform 300ms ease, box-shadow 300ms ease, background-color 300ms ease;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);

  .navbar-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: constants.$section-width;
    max-width: constants.$section-maxWidth;
    margin: auto;
    height: navConstants.$navbar-height;

    @include mixins.respond-max(constants.$breakpoint) {
      height: navConstants.$navbar-height_mobile;
    }
  }

  .logo {
    background-position: left;
    cursor: pointer;
    margin: auto;
    position: absolute;
    z-index: 113;
    left: 0;
    transition: 0.3s ease all;
    top: 50%;
    bottom: 50%;
  }

  .logo-offset {
    bottom: 0;
    top: 100%;
  }

  .btns-container {
    display: flex;
    align-items: center;
    height: 100%;

    @include mixins.respond-max(constants.$breakpoint) {
      display: none;
    }
  }

  .navbar-btn-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .navbar-btn {
    @include fonts.acumin-pro;
    cursor: pointer;
  }

  .navbar-btn.active {
    font-weight: 600;
  }

  .navbar-btn-border-bottom {
    width: 70%;
    position: absolute;
    bottom: 0;
  }

  .line-separator {
    height: 40px;
    width: 1px;
    opacity: 0.4;
  }

  .navbar-btn,
  .line-separator {
    margin: 22.5px;
  }

  a:last-child {
    margin-right: 0;
  }

  .icon-dropdownArrow {
    width: 10px;
    height: 10px;
    margin-left: 10px;
    position: relative;
    top: 1px;
    transition: 0.3s ease transform;
  }

  .icon-dropdownArrow.rotateOpen {
    transform: rotate(180deg);
  }

  .navbar-mobileMenu {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 0.3s ease all, padding-top 0ms;

    &.closed {
      visibility: hidden;
      opacity: 0;
    }

    &.open {
      visibility: visible;
      padding-top: 100px;
      background: black;
      height: 100vh;
      width: 100%;
      opacity: 1;
      z-index: 100;
      transform-origin: center center;
      top: 0 !important;
      left: 0 !important;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .navbar-mobileMenu_width {
      width: 90%;
    }

    .navbar-btn {
      margin: 10px 10px 10px 0;
    }

    .navbar-btn_demoMobile {
      margin-top: 30px;
      align-self: center;
    }

    .companyMobileLinks-padding {
      padding-left: 20px;
    }
  }
}

.navbar-hidden {
  transform: translate3d(0, calc(-100% - 2px), 0);
}

.navbar-box-shadow-none {
  box-shadow: none;
}

//====================================
// NAVBAR COLOR VARIATIONS
//====================================

// BLACK
%nav-black {
  background-color: black;
  color: white;

  .navbar-btn {
    color: #d0d0d0;
  }

  .navbar-btn:hover,
  .navbar-btn.active {
    color: white;
  }

  .navbar-btn.active + .navbar-btn-border-bottom {
    border-bottom: 1px solid white;
  }

  .line-separator {
    background-color: #4b4b4b;
  }

  .svg-hamburger {
    stroke: white;
  }
}

.navbar-container.black {
  @extend %nav-black;
}

.navbar-container.trans-black {
  @extend %nav-black;
  background-color: transparent;

  &.navbar-scrolling {
    background-color: black;
  }
}

// WHITE
%nav-white {
  background-color: white;

  .navbar-btn {
    color: constants.$nav-btn_gray;
  }

  .navbar-btn.active,
  .navbar-btn:hover {
    color: black;
  }

  .navbar-btn.active + .navbar-btn-border-bottom {
    border-bottom: 1px solid black;
  }

  .line-separator {
    background-color: #e1e1e1;
  }

  .navbar-mobileMenu.open {
    background: white;
  }

  .svg-hamburger {
    stroke: black;
  }
}

.navbar-container.white {
  @extend %nav-white;
}

.navbar-container.trans-white {
  @extend %nav-white;
  background-color: transparent;

  // transparent white navbar gets black navbar btn links
  .navbar-btn {
    color: black;
  }
  .navbar-btn:hover {
    color: rgb(80, 80, 80);
  }

  &.navbar-scrolling {
    background-color: white;
  }
}

// GREEN
.navbar-container.green {
  background-color: transparent;
  color: white;

  &.navbar-scrolling {
    background-color: constants.$security-green;
  }

  .navbar-btn {
    color: #eeeeee;
  }

  .navbar-btn:hover,
  .navbar-btn.active {
    color: white;
  }

  .navbar-btn.active + .navbar-btn-border-bottom {
    border-bottom: 1px solid white;
  }

  .line-separator {
    background-color: #ffffff;
  }

  .svg-hamburger {
    stroke: white;
  }
}

.navbar-container.blue {
  background-color: transparent;
  color: white;

  &.navbar-scrolling {
    background-color: constants.$security-blue;
  }

  .navbar-btn {
    color: #eeeeee;
  }

  .navbar-btn:hover,
  .navbar-btn.active {
    color: white;
  }

  .navbar-btn.active + .navbar-btn-border-bottom {
    border-bottom: 1px solid white;
  }

  .line-separator {
    background-color: #ffffff;
  }

  .svg-hamburger {
    stroke: white;
  }
}
