@use "./teamMemberCard.scss";
@use "./teamSection.scss";
@use "./timelineSection.scss";
@use "./coreValuesSection.scss";
@use "./testimonySection.scss";
@use "./backedBySection.scss";
@use "../mixins";
@use "../constants";

.aboutPage-container {
  background: linear-gradient(to bottom, #3c3c3c 2%, transparent 2%);
}

.aboutPage-heroSection {
  height: 500px;
  position: relative;
  padding-bottom: 200px !important;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    height: auto;
  }

  .founders-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .founders {
    width: 100%;
    mask-image: linear-gradient(transparent 0%, #000, transparent 100%);
  }

  .text-wrapper {
    position: relative;
  }

  h1 {
    margin: 10px 0;
  }

  h3 {
    margin: 0;
    line-height: 51px;
  }

  .h3-large {
    font-size: 38px;
    max-width: 650px;
  }

  .subheader-2 {
    color: #747474;
  }
}
