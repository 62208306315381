@use "./waves.scss";
@use "./numbersSection.scss";
@use "./joinSection.scss";
@use "./solutionsSection.scss";
@use "./heroSection.scss";
@use "../mixins";
@use "../constants";

.homePage-container {
  h2 {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      font-size: 36px;
      line-height: 48px;
    }
  }
}
