.section {
  padding: 100px 0;

  background: linear-gradient(rgb(248 248 248), white);
}

.marquee {
  --gap: 4rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.marquee_content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll 25s linear infinite;
}

.logo {
  height: 60px;
  object-fit: contain;
}

.lines {
  width: 75%;
}
